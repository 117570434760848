// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  //----------------------  FOR PRODUCTION ------------------------------

    production: true,
    name:'',
    version: '2023.2.3',
    serverWS: 'wss://patpat360.avaservice.eu/ws',
    serverURI: 'https://patpat360.avaservice.eu/',

    googleClientId: "201225183218-d1e1in9ic03fjcn94n33unk37e2ktinp.apps.googleusercontent.com",

    ShowPersonalReport: false,

    ShowManagerEvaluations:false,
    ShowGoals:false,
    ShowProjectTeams:false,

    ShowGrowthSummary:false,
    ShowBossAndSelfEvaluations:false,

    showClone:false,

    ShowCycleAdvanced:false,

    ShowPerfomanceReviews:false
    

  //***************************************************************************** */
  // production: false,
  // name: 'TEST',
  // version: '2023.2.1',

  // serverWS: 'wss://tools.avaservice.eu:8443/patpat_test/ws',
  // serverURI: 'https://tools.avaservice.eu:8443/patpat_test/',

  // googleClientId:
  //   '201225183218-d1e1in9ic03fjcn94n33unk37e2ktinp.apps.googleusercontent.com',

  // ShowPersonalReport: true,

  // ShowManagerEvaluations: true,

  // ShowGoals: true,
  // ShowProjectTeams: true,

  // ShowGrowthSummary: true,
  // ShowBossAndSelfEvaluations: true,

  // showClone: true,

  // ShowCycleAdvanced: true,

  // ShowPerfomanceReviews: true,
};
